import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { AccountTemplate } from "@/components/templates";

export default function Home() {
  return (
    <div>
      <Head>
        <title>My account</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AccountTemplate />
    </div>
  );
}

export const getServerSideProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "AccountSummary",
      "OrderList",
      "DesignItem",
      "OrderStatus",
      "ProgressSteps",
      "OrderView",
      "OrderViewItem",
      "UserAddressCard",
      "AddressList",
      "Address",
    ])),
  },
});
